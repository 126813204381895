import BottomSheet from 'components/BottomSheet';
import Button, { Size, Variants } from 'components/Button';
import Confirmation from 'components/Confirmation';
import EditDateApproved from 'components/EditDateApproved';
import useModal from 'hooks/useModal';
import {
  approve,
  permDeleteJob,
  reject,
  softDeleteJob,
  undeleteJob,
} from 'queries/job';
import React from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { readAxiosErr } from 'utils/misc';
import { formatServerTime } from 'utils/time';
import Checkbox from './Checkbox';
import Icon from 'components/Icon';
import clx from 'classnames';

type AppProps = {
  data: Record<string, any>;
  renderInTable?: boolean;
};

const Deleted: React.FC<AppProps> = ({ data, renderInTable = false }) => {
  const { jobId = '' } = useParams();
  const queryClient = useQueryClient();
  const [actionOpen, showActions, closeActions] = useModal();
  const [deleteOpen, showDelete, closeDelete] = useModal();
  const [permDeleteOpen, showPermDelete, closePermDelete] = useModal();
  const navigate = useNavigate();

  const callback = (postAction?: any) => ({
    onError: (err: any) => {
      toast.error(readAxiosErr(err));
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(['job-last-checklist', jobId]),
        queryClient.invalidateQueries(['job-checklist', jobId]),
      ]);
      queryClient.invalidateQueries(['job-detail', jobId]);
      queryClient.invalidateQueries(['jobs']);
      postAction?.();
    },
  });

  const deleteMutation = useMutation(() => softDeleteJob(jobId), callback());

  const undeleteMutation = useMutation(() => undeleteJob(jobId), callback());

  const permDeleteMutation = useMutation(
    () => permDeleteJob(jobId),
    callback(() => {
      return navigate('/prospects?tab=all');
    })
  );

  const incomplete = () => {
    return (
      <div className="">
        <div className="apart">
          <div className="value">Not Deleted</div>
          {data?.permissions?.can_delete && (
            <div>
              <Button
                variant={Variants.Tag}
                label="Delete"
                size={Size.Small}
                onClick={showDelete}
                loading={deleteMutation.isLoading}
              />
            </div>
          )}
        </div>
        {!!data?.message && (
          <div className="text-xs text-error">* {data?.message}</div>
        )}
        {deleteOpen && (
          <Confirmation
            title="Delete Job?"
            message="This action will soft delete the job. You will still be able to view the job in the 'Dead Leads' section and restore it if needed."
            open={deleteOpen}
            closeConfirm={closeDelete}
            onClick={() => deleteMutation.mutate()}
            isLoading={deleteMutation.isLoading}
          />
        )}
      </div>
    );
  };

  const complete = () => {
    return (
      <div>
        <div className="apart">
          <div className="value">Deleted</div>
          <div>
            <Button
              variant={Variants.Tag}
              label="Actions"
              size={Size.Small}
              onClick={showActions}
            />
          </div>
        </div>
        <div className="-mt-1">
          <span className="key">Deleted By: </span>
          <span className="key">{data.deleted_by?.name}</span>
        </div>
        <div className="-mt-1">
          <span className="key">Deleted At: </span>
          <span className="key">
            {formatServerTime({
              date: data.date,
              parseFormat: 'YYYY-MM-DDThh:mm:ssZ',
              includeTime: true,
            })}
          </span>
        </div>

        <BottomSheet open={actionOpen}>
          <div className="pb-4">
            <div className="flex justify-between">
              <div className="page-heading">Actions</div>
              <div>
                <Icon name="close" size={32} onClick={closeActions} />
              </div>
            </div>
            <div className="mt-4 space-y-4">
              {data?.permissions?.can_undelete && (
                <Button
                  label="Restore Job"
                  size={Size.Big}
                  variant={Variants.Tag}
                  onClick={() => undeleteMutation.mutate()}
                  loading={undeleteMutation.isLoading}
                />
              )}
              {data?.permissions?.can_perm_delete && (
                <Button
                  label="Permanently Delete"
                  size={Size.Big}
                  variant={Variants.ErrorNegative}
                  onClick={showPermDelete}
                />
              )}
            </div>
          </div>
        </BottomSheet>
        {permDeleteOpen && (
          <Confirmation
            title="Permanently Delete Job?"
            message="Are you sure you want to permanently delete this job? This can not be undone."
            open={permDeleteOpen}
            closeConfirm={closePermDelete}
            onClick={() => permDeleteMutation.mutate()}
            isLoading={permDeleteMutation.isLoading}
          />
        )}
      </div>
    );
  };

  return (
    <div className={clx({ card: !renderInTable, 'px-2 pb-1': renderInTable })}>
      <div className="flex space-x-2">
        <Checkbox checked={data.complete} />
        <div className="w-full">
          {data.complete ? complete() : incomplete()}
        </div>
      </div>
    </div>
  );
};

export default Deleted;
