import Loader, { Variant as LoaderVariant } from 'components/Loader';
import SubscriptionExpired from 'components/SubscriptionExpired';
import React, { useState, createContext, useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import apiService from 'utils/apiService';
import {
  IMPERSONATE_SESSION_KEY,
  SESSION_KEY,
  SHOW_SIDEBAR,
} from 'utils/constants';
import { getItem, reset, setItem } from 'utils/localstorage';

interface ICompanyBasicInfo {
  id: string;
  name: string;
  abbr: string;
  is_active: boolean;
  logo?: string;
}

interface IUserInfo {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  title: string;
  email: string;
  phone: string;
  signature: string;
  initials: string;
  profile_image: string;
  subscription: Record<string, any>;
  primary_company: ICompanyBasicInfo;
  default_region: Record<string, any>;
  companies: ICompanyBasicInfo[];
  default_values: Record<string, any>;
  permissions: Record<string, boolean>;
  integrations: Record<string, boolean>;
  controls: Record<string, boolean>;
  connected_account: Record<string, any> | null;
  is_email_verified: boolean;
  is_phone_verified: boolean;
  is_term_condition_accepted: boolean;
  is_license_accepted: boolean;
  zendesk_jwt: string;
  show_onboarding_steps: boolean;
  progress: Record<string, boolean>;
  is_toolkit_user: boolean;
  is_enterprise_user: boolean;
}

export const AuthContext = createContext({
  isAuthenticated: false,
  userInfo: null as null | IUserInfo,
  isOnboardComplete: false,
  embedMode: false,
  collapse: false,
  setCollapse: (...args: any) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  authenticate: (user: IUserInfo, token: string): void => {},
  resetAuth: (): void => {},
  ninjaMode: false,
  setNinjaMode: (...args: any): void => {},
  updateUserField: (field: string, value: any) => {},
  updateUserProgress: (field: string, value: any) => {},
  setTrialExpired: (...args: any) => {},
});

type AppProps = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<AppProps> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState<IUserInfo | null>(null);
  const [embedMode, setEmbedMode] = useState(false);
  const [ninjaMode, setNinjaMode] = useState(false);
  const [trialExpired, setTrialExpired] = useState(false);
  const [collapse, setCollapse] = useState(
    getItem(SHOW_SIDEBAR) === 'collapse'
  );

  useEffect(() => {
    if (collapse) {
      setItem(SHOW_SIDEBAR, 'collapse');
    } else {
      setItem(SHOW_SIDEBAR, 'expand');
    }
  }, [collapse]);

  useEffect(() => {
    if (searchParams.get('mode') === 'embed') {
      setEmbedMode(true);
    }
    fetchMe();
    if (getItem(IMPERSONATE_SESSION_KEY)) {
      setNinjaMode(true);
    }
  }, []);

  const fetchMe = async () => {
    const sid = searchParams.get('sid') || null;
    if (sid) {
      reset();
      setItem(SESSION_KEY, sid);
      searchParams.delete('sid');
      setSearchParams(searchParams);
    }
    if (!getItem(SESSION_KEY)) {
      setLoading(false);
      return <Navigate to="/login" />;
    }
    try {
      const { data } = await apiService.get('/api/light/auth/me/');
      authenticate(data);
      if (data?.subscription?.status === 'EXPIRED') {
        setTrialExpired(true);
      }
    } catch (e: any) {
      const status = e.response?.status || null;
      if (status === 401) {
        setLoading(false);
        return <Navigate to="/login" />;
      }
    }
  };

  const authenticate = (user: IUserInfo, token?: string) => {
    setIsAuthenticated(true);
    setUserInfo(user);
    if (token) {
      setItem(SESSION_KEY, token);
    }
    if (searchParams.get('mode') === 'embed') {
      setEmbedMode(true);
    }
    setLoading(false);

    // setTimeout(() => {
    //   // @ts-ignore
    //   window.zE('messenger', 'loginUser', function (callback: any) {
    //     callback(user.zendesk_jwt);
    //   });

    //   // if (isMobile) {
    //   //   // @ts-ignore
    //   //   window.zE('messenger:set', 'zIndex', 100);
    //   // }
    // }, 5000);

    // if (!(user?.is_email_verified && user?.is_phone_verified)) {
    //   return navigate('/onboard');
    // }
  };

  const resetAuth = () => {
    setIsAuthenticated(false);
    setUserInfo(null);
    reset();
  };

  const updateUserField = (field: string, value: any) => {
    setUserInfo((user: any) => ({ ...user, [field]: value }));
  };

  const updateUserProgress = (field: string, value: any) => {
    setUserInfo((user: any) => ({
      ...user,
      progress: { ...user?.progress, [field]: value },
    }));
  };

  if (loading) {
    return (
      <Loader
        label="Verifying your session"
        variant={LoaderVariant.RoofLoader}
      />
    );
  }

  return (
    <>
      <AuthContext.Provider
        value={{
          isAuthenticated,
          userInfo,
          isOnboardComplete:
            (userInfo?.is_email_verified && userInfo?.is_phone_verified) ||
            false,
          resetAuth,
          authenticate,
          collapse,
          setCollapse,
          embedMode,
          ninjaMode,
          setNinjaMode,
          updateUserField,
          updateUserProgress,
          setTrialExpired,
        }}
      >
        {children}
        {trialExpired && <SubscriptionExpired open={trialExpired} />}
      </AuthContext.Provider>
    </>
  );
};

export default AuthProvider;
