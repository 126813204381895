import React, { useMemo, useState } from 'react';
import { SubmitHandler, useController } from 'react-hook-form';
import clx from 'classnames';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { GREY_COLOR, PRIMARY_COLOR, TEXT_COLOR } from 'utils/constants';
import { orderBy, find } from 'lodash';
import useModal from 'hooks/useModal';
import useMedia from 'hooks/useMedia';
import BottomSheet from 'components/BottomSheet';
import Icon, { Loader } from 'components/Icon';
import Tooltip from 'components/Tooltip';

type AppProps = {
  label: string;
  labelAction?: React.ReactNode;
  name: string;
  placeholder?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
  className?: string;
  control: any;
  optionsPromise: any;
  addCurrentValue?: boolean;
  onChange?: SubmitHandler<any>;
  error?: string;
  required?: boolean;
  loading?: boolean;
  disabled?: boolean;
  orderByLabel?: boolean;
  isCreatable?: boolean;
  onCreate?: (...args: any) => any;
  filterFn?: (choices: any) => any;
  formatter?: (choices: any) => any;
  postSelect?: (selected: any) => any;
  position?: string;
  dependencies?: [any];
  info?: string;
};

const AsyncSelect: React.FC<AppProps> = ({
  name,
  label,
  labelAction,
  control,
  placeholder = 'Select option',
  isClearable = false,
  isSearchable = true,
  className = '',
  optionsPromise,
  error = null,
  required = false,
  loading = false,
  disabled = false,
  orderByLabel = true,
  isCreatable = false,
  filterFn = (choices: any) => choices,
  formatter = (choices: any) => choices,
  postSelect = null,
  position = 'auto',
  dependencies = [],
  addCurrentValue = false,
  onCreate = () => null,
  info = '',
}) => {
  const { isMobile } = useMedia();
  const { field } = useController({ name, control });
  const [select, showSelect, closeSelect] = useModal();
  const [search, setSearch] = useState('');
  // const [create, setCreate] = useState<Record<string, any> | null>(null);

  const selectStyle = clx(
    'w-full rounded-lg outline-none focus:outline-none focus:ring-0',
    {
      'focus:border-error-dark border-error': !!error,
      'focus:border-primary border-border ': !error,
    }
  );

  const { data, isLoading } = optionsPromise();
  const _data = useMemo(() => {
    let d =
      (orderByLabel ? orderBy(data, ['label']) : data)?.filter(
        (i: any) => i.label
      ) || [];
    if (addCurrentValue) {
      d = [...d, { value: field.value, label: field.value }];
    }
    // if (isCreatable) {
    //   d = [...d, { value: 'create_new', label: 'Create New' }];
    // }
    return formatter(filterFn(d));
  }, [data, field.value, orderByLabel, dependencies]);

  const _filteredData = useMemo(() => {
    if (search) {
      const items = _data.filter((d: any) =>
        d?.label?.toLowerCase().includes(search?.toLowerCase())
      );
      if (!isCreatable || find(_data, { label: search })) {
        return items;
      }
      return [...items, { label: search, value: search, create: true }];
    }
    return _data;
  }, [_data, search]);

  const onChange = (value: string) => {
    if (value === 'create_new') {
      return onCreate('');
    }
    field.onChange(value);
    if (postSelect) {
      postSelect(find(_data, { value }));
    }
  };

  const _isSearchable = useMemo(() => {
    if (isLoading) {
      return false;
    }
    if (isCreatable) {
      return true;
    }
    if (_data?.length < 8) {
      return false;
    }
    return isSearchable;
  }, [_data, isLoading, isCreatable]);

  const value = find(_data, { value: field.value });

  if (isMobile) {
    return (
      <div className={`space-y-1 mb-2 ${className}`}>
        <div className="apart w-full">
          <div className="text-text-medium text-sm">
            {label}
            {required && <span className="text-error">*</span>}
          </div>
          <div>{labelAction}</div>
        </div>
        <div
          className={clx(
            'border h-10 relative px-2 v-center text-base bg-white',
            selectStyle,
            {
              '!bg-gray-200': loading || disabled,
            }
          )}
          onClick={() => (loading || disabled ? null : showSelect())}
        >
          {value?.value ? (
            <span className="text-text">{value?.label}</span>
          ) : (
            <span className="text-text-light">
              {placeholder || 'Select Value'}
            </span>
          )}
          <div className="absolute right-2">
            <Icon
              name="caret-down-outline"
              size={22}
              className="text-text-light"
            />
          </div>
        </div>
        <BottomSheet open={select}>
          <div className="pb-4">
            <div className="flex justify-between">
              <div className="text-lg font-bold mb-4">{label}</div>
              <div>
                <Icon
                  name="close"
                  size={28}
                  onClick={() => {
                    closeSelect();
                    setSearch('');
                  }}
                  className="cursor-pointer"
                />
              </div>
            </div>
            {isSearchable && (
              <div>
                <button></button>
                <input
                  className="w-full rounded-md px-2 py-1.5 outline-none focus:border-primary border border-border placeholder:text-gray-400 text-text focus:outline-none focus:ring-0"
                  placeholder={isCreatable ? 'Search or Create new' : 'Search'}
                  onChange={(e) => setSearch(e.target.value)}
                  autoFocus={false}
                />
              </div>
            )}
            {isClearable && (
              <div className={clx({ 'mt-4': isSearchable })}>
                <div
                  className="text-primary text-sm flex justify-end"
                  onClick={() => onChange('')}
                >
                  Clear Selection
                </div>
              </div>
            )}
            <div
              className={clx('max-h-96 overflow-y-auto', {
                'mt-4': isSearchable && !isClearable,
                'mt-2': !isSearchable && isClearable,
              })}
            >
              {isLoading && <Loader />}
              {_filteredData?.map((d: any, i: number) => (
                <div
                  key={`${d.value}-${i}`}
                  className={
                    'border-b border-border py-2.5 px-2 apart items-center text-sm'
                  }
                  onClick={() => {
                    if (d.create) {
                      onCreate?.(d.value);
                    }
                    onChange(d.value);
                    setSearch('');
                    closeSelect();
                  }}
                >
                  <span
                    className={clx({ 'font-bold': d.value === value?.value })}
                  >
                    {d?.create && (
                      <span className="text-xs text-primary">Create </span>
                    )}
                    <span className={clx({ 'text-base font-bold': d.create })}>
                      {d.label}
                    </span>
                  </span>
                  {d.value === value?.value && (
                    <Icon name="tick" className="text-success" size={16} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </BottomSheet>
      </div>
    );
  }

  const RSelect = isCreatable ? CreatableSelect : Select;

  return (
    <div className={`space-y-1 mb-2 ${className}`}>
      <div className="text-text-medium text-sm flex justify-between w-full">
        <div className="v-center">
          {label}
          {required && <span className="text-error">*</span>}
          {info && (
            <Tooltip tooltipContent={info}>
              <Icon name="info" className="text-text-lighter ml-2" size={14} />
            </Tooltip>
          )}
        </div>
        {labelAction}
      </div>

      <RSelect
        {...field}
        isSearchable={_isSearchable}
        isLoading={isLoading}
        options={_data}
        value={value}
        onChange={(v: any) => onChange(v?.value)}
        placeholder={
          <span className="text-gray-400 text-sm">{placeholder}</span>
        }
        isClearable={isClearable}
        className={selectStyle}
        styles={customStyles}
        onCreateOption={(v) => {
          onCreate?.(v);
        }}
        components={{ IndicatorSeparator: () => null }}
        isDisabled={loading || disabled}
        menuPortalTarget={document.body}
        // @ts-ignore
        menuPlacement={position}
      />
      {error && <div className="text-error text-xs">*{error}</div>}
    </div>
  );
};

AsyncSelect.displayName = 'AsyncSelect';

export default AsyncSelect;

const customStyles = {
  input: (provided: any) => ({
    ...provided,
    color: TEXT_COLOR,
    fontSize: 14,
    height: '32px',
    'input:focus': {
      boxShadow: 'none',
      borderColor: 'green',
    },
  }),
  control: (base: any, state: any) => ({
    ...base,
    boxShadow: 'none',
    color: TEXT_COLOR,
    borderRadius: 8,
    borderColor: state.isFocused ? PRIMARY_COLOR : GREY_COLOR,
    backgroundColor: state.isDisabled ? '#e5e7eb' : 'white',
    height: '42px',
  }),
  singleValue: (base: any) => ({
    ...base,
    color: TEXT_COLOR,
  }),
  option: (base: any, { isFocused }: any) => {
    let backgroundColor = '#fff';
    let color = TEXT_COLOR;
    if (isFocused) {
      backgroundColor = PRIMARY_COLOR;
      color = '#fff';
    }
    return {
      ...base,
      backgroundColor,
      color,
      fontSize: 14,
    };
  },
  noOptionsMessage: (base: any) => ({
    ...base,
    fontSize: 14,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    padding: 0,
    margin: 0,
  }),
  menuPortal: (base: any) => ({
    ...base,
    zIndex: 99999,
  }),
};
