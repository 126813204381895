import React from 'react';
import Skeleton from 'react-loading-skeleton';
import clx from 'classnames';
import useMedia from 'hooks/useMedia';

export enum ContentType {
  Grid = 'grid',
  Card = 'card',
  Form = 'form',
  Table = 'table',
  List = 'list',
}

type AppProps = {
  includeHeader?: boolean;
  contentType?: ContentType;
  size?: number;
  header?: Record<string, any>;
  grid?: Record<string, any>;
  card?: Record<string, any>;
  list?: Record<string, any>;
  className?: string;
};

const SkeletonLoader: React.FC<AppProps> = ({
  includeHeader = true,
  contentType = ContentType.Grid,
  size = 10,
  header = { size: { height: 40 } },
  grid = { size: { height: 160, width: 186 }, count: 2 },
  // list = {},
  card = { size: { height: 128 } },
  className = '',
}) => {
  const { isMobile } = useMedia();

  if (contentType === ContentType.Form) {
    return (
      <div className="px-2 py-4 bg-white">
        {Array(size)
          .fill(null)
          .map((_, i) => {
            return (
              <div key={i} className="mb-3">
                <Skeleton height={24} className="" />
                <Skeleton height={36} className="" />
              </div>
            );
          })}
      </div>
    );
  }

  if (contentType === ContentType.List) {
    return (
      <div className={clx('p-2', className)}>
        {Array(size)
          .fill(null)
          .map((_, i) => {
            return (
              <div key={i} className="mb-2">
                <Skeleton height={32} className="" />
              </div>
            );
          })}
      </div>
    );
  }

  if (contentType === ContentType.Table) {
    return (
      <div className={clx('px-2 py-4 bg-white', className)}>
        <div className="mb-4">
          <div className="grid grid-cols-6 space-x-16">
            {Array(6)
              .fill(null)
              .map((_, i) => {
                return (
                  <div key={i}>
                    <Skeleton height={36} />
                  </div>
                );
              })}
          </div>
        </div>
        {Array(size)
          .fill(null)
          .map((_, i) => {
            return (
              <div key={i} className="mb-2">
                <Skeleton height={32} className="" />
              </div>
            );
          })}
        <div className="z-10 fixed bottom-0 left-0 right-0 pb-4 bg-white px-4 top-shadow">
          <div className="flex justify-between mt-4">
            <div className="w-1/3"></div>
            <div className="flex-1 flex justify-end space-x-4">
              {Array(8)
                .fill(null)
                .map((_, i) => {
                  return (
                    <div key={i}>
                      <Skeleton height={36} width={56} />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full bg-white pb-8 px-2">
      {includeHeader && <Skeleton height={header.size.height} />}
      {(() => {
        if (contentType === ContentType.Grid) {
          return (
            <div className="flex flex-wrap mt-4">
              {Array(size * grid.count)
                .fill(null)
                .map((_, i) => {
                  return (
                    <div
                      key={i}
                      className={clx('rounded-md shadow-sm relative', {
                        'mr-6 mb-6': !isMobile,
                        'mr-3 mb-2': isMobile,
                      })}
                    >
                      <Skeleton
                        height={grid.size.height}
                        width={grid.size.width}
                      />
                    </div>
                  );
                })}
            </div>
          );
        }

        if (contentType === ContentType.Card) {
          return (
            <div className="mt-2">
              {Array(size)
                .fill(null)
                .map((_, i) => {
                  return (
                    <div key={i} className="mb-2">
                      <Skeleton height={card.size.height} />
                    </div>
                  );
                })}
            </div>
          );
        }
      })()}
    </div>
  );
};

export default SkeletonLoader;
