import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import clx from 'classnames';
import Tooltip from 'components/Tooltip';
import Icon from 'components/Icon';

export enum Size {
  Small = 'small',
  Medium = 'medium',
}

export enum Variant {
  Text = 'text',
  Number = 'number',
  Password = 'password',
  Email = 'email',
  Phone = 'tel',
}

export enum Border {
  Full = 'full',
  Underline = 'underline',
}

type AppProps = {
  label: string;
  labelAction?: React.ReactNode;
  name: string;
  border?: Border;
  placeholder?: string;
  className?: string;
  style?: Record<string, any>;
  onChange: SubmitHandler<any>;
  variant?: Variant;
  error?: string;
  required?: boolean;
  autoFocus?: boolean;
  loading?: boolean;
  disabled?: boolean;
  postBlur?: any;
  info?: string;
};

const Input = React.forwardRef<HTMLInputElement, AppProps>(
  (
    {
      name,
      label,
      labelAction = null,
      border = Border.Full,
      placeholder = '',
      onChange,
      className = '',
      style = {},
      variant = Variant.Text,
      error = null,
      required = false,
      autoFocus = false,
      loading = false,
      disabled = false,
      postBlur,
      info = '',
    },
    ref
  ) => {
    const inputStyle = clx(
      'w-full outline-none placeholder:text-gray-300 text-text focus:outline-none focus:ring-0',
      {
        'focus:border-error-dark border-error': !!error,
        'focus:border-primary border-border ': !error,
        'rounded-lg ': border !== Border.Underline,
        'border-0 border-b-2': border === Border.Underline,
        'bg-gray-200': disabled || loading,
      }
    );

    return (
      <div className={`space-y-1 mb-2 ${className}`}>
        <div className="apart w-full">
          <div className="text-text-medium text-sm v-center space-x-2">
            <div>
              {label}
              {required && <span className="text-error">*</span>}
            </div>
            {!!info && (
              <div>
                <Tooltip tooltipContent={info} width="240px">
                  <Icon
                    name="info"
                    size={14}
                    className="text-text-lighter relative"
                  />
                </Tooltip>
              </div>
            )}
          </div>
          <div>{labelAction}</div>
        </div>
        <input
          className={inputStyle}
          style={style}
          name={name}
          type={variant}
          placeholder={placeholder}
          onChange={onChange}
          ref={ref}
          step="any"
          onFocus={(e) => {
            if (
              variant === Variant.Number &&
              ['0.00', '0', '0.0'].includes(e.target.value)
            ) {
              e.target.value = '';
            }
          }}
          onBlur={(e) => {
            if (variant === Variant.Number && e.target.value === '') {
              e.target.value = '0';
            }
            postBlur?.();
          }}
          autoFocus={autoFocus}
          disabled={disabled || loading}
          onWheel={(e) => e.currentTarget.blur()}
        />
        {error && <div className="text-error text-xs">*{error}</div>}
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
